import {defineMessages} from "react-intl.macro";
import React from "react";

const messages = defineMessages({
    'document_title': {
        id: "document_title",
        defaultMessage: "Assessmentworks"
    },

    // Missing text
    'Missing translation for': {
        id: "Missing translation for",
        defaultMessage: "Missing translation for"
    },

    // Notifications
    'notification_success': {
        id: "notification.success",
        defaultMessage: "Successfully saved!"
    },
    'notification_error': {
        id: "notification.error",
        defaultMessage: "System error!{br}Please try again later.",
        values: {
            br: <br/>
        }
    },

    // Data protection
    'data_protection_text': {
        id: 'data_protection_text',
        defaultMessage: "{openP}Declaration of consent to the processing of personal data within the framework of the employment contract / application in connection with the test procedure:{closeP}" +
            "{openP}The purpose of the test procedure is to identify high potentials, to make high potentials visible to decision-makers, to develop talents throughout the company and to retain high potentials, and to ensure a good fit between the applicant and the requirements of the position.{break}" +
            "The following data is processed and used by us as an external provider: personal data (name, age, gender, position), total result of the test as well as the individual scales.{break}{break}" +
            "The collection, processing or use of personal employee data is possible on the basis of Art. 88 DSGVO, § 26 Para. 1 BDSG-neu without the consent of the person concerned, insofar as this is necessary for the implementation of the employment relationship. Since your data provided will be used beyond this purpose for the above-mentioned purposes within the meaning of the DSGVO, your consent is required for the intended purpose.{break}{break}" +
            "I agree that in the context of my employment relationship or my application the data mentioned may be collected, processed and used in compliance with the provisions of the DSGVO.{break}{break}" +
            "You can revoke this consent at any time by a written declaration to your employer / the company without giving reasons.{closeP}",
        values: {
            openP: '<p>',
            closeP: '</p>',
            break: '<br/>'
        }
    },

    // Error
    'error_email': {
        id: "error.email",
        defaultMessage: "Invalid E-Mail!"
    },
    'error_password': {
        id: "error.password",
        defaultMessage: "The password must be at least 8 characters long!"
    },
    'error_password_repeat': {
        id: "error.password_repeat",
        defaultMessage: "Passwords do not match!"
    },
    'error_renderer': {
        id: "error.renderer",
        defaultMessage: "Please try again or contact us at {mail}",
        values: {
            mail: <a href={'mailto:service@brooklynmaxx.com'}>service@brooklynmaxx.com</a>
        }
    },
    'error_register_email': {
        id: "error.register.email",
        defaultMessage: "E-Mail is already in use!"
    },
    'error_get_ownership': {
        id: "error.get_ownership",
        defaultMessage: "Pin can't be owned!"
    },
    'error_get_pin': {
        id: "error.get_pin",
        defaultMessage: "Can't get pin!"
    },

    // Select languages
    'languages_select': {
        id: "languages.select",
        defaultMessage: "Select language"
    },
    'english': {
        id: "english",
        defaultMessage: "English"
    },
    'german': {
        id: "german",
        defaultMessage: "German"
    },

    // Headlines
    'set_pin_headline': {
        id: "set_pin.headline",
        defaultMessage: "Insert your pin"
    },
    'login_headline': {
        id: "login.headline",
        defaultMessage: "Welcome!"
    },

    // Cameratag
    'cameratag_start_record': {
        id: "cameratag.start_record",
        defaultMessage: "Start record"
    },
    'cameratag_waiting_for_hardware': {
        id: "cameratag.waiting_for_hardware",
        defaultMessage: "Waiting for hardware"
    },
    'cameratag_error_text': {
        id: "cameratag.error.text",
        defaultMessage: "Hardware Access Denied"
    },
    'cameratag_error_button': {
        id: "cameratag.error.button",
        defaultMessage: "Restart"
    },
    'cameratag_uploading': {
        id: "cameratag.uploading",
        defaultMessage: "uploading..."
    },
    'cameratag_published': {
        id: "cameratag.published",
        defaultMessage: "saved"
    },
    'cameratag_publishing': {
        id: "cameratag.publishing",
        defaultMessage: "saving"
    },
    'cameratag_connecting': {
        id: "cameratag.connecting",
        defaultMessage: "conecting..."
    },
    'cameratag_already_recorded': {
        id: "cameratag.already_recorded",
        defaultMessage: "Video already recorded"
    },

    'theme_switch': {
        id: "theme.switch",
        defaultMessage: "Change theme color"
    },
    'pin': {
        id: "pin",
        defaultMessage: "Pin"
    },
    'set': {
        id: "set.headline",
        defaultMessage: "Set"
    },
    'login': {
        id: "login",
        defaultMessage: "Login"
    },
    'register': {
        id: "register",
        defaultMessage: "Register"
    },
    'submit': {
        id: "submit",
        defaultMessage: "Submit"
    },
    'email': {
        id: "email",
        defaultMessage: "E-Mail"
    },
    'password': {
        id: "password",
        defaultMessage: "Password"
    },
    'password_repeat': {
        id: "password.repeat",
        defaultMessage: "Repeat password"
    },
    'first_name': {
        id: "first_name",
        defaultMessage: "First name"
    },
    'last_name': {
        id: "last_name",
        defaultMessage: "Last name"
    },
    'gender': {
        id: "gender",
        defaultMessage: "Gender"
    },
    'invalid_credentials': {
        id: "invalid_credentials",
        defaultMessage: "Invalid credentials!"
    },
    'data_protection_headline': {
        id: "data_protection.headline",
        defaultMessage: "Privacy Policy"
    },
    'data_protection_accept': {
        id: "data_protection.accept",
        defaultMessage: "Accept data protection"
    },
    'continue': {
        id: "continue",
        defaultMessage: "Continue"
    },
    'logout': {
        id: "logout",
        defaultMessage: "Logout"
    },
    'renderer_finished': {
        id: "renderer.finished",
        defaultMessage: "Your test is finished. You can close your browser."
    },
    'text': {
        id: "text",
        defaultMessage: "Text"
    },
    'forgot_password': {
        id: "forgot_password",
        defaultMessage: "Forgot password?"
    },
    'back': {
        id: "back",
        defaultMessage: "back"
    },
    'forgot_password_notification_info': {
        id: "forgot_password.notification.info",
        defaultMessage: "When the email was found in our system, an E-Mail with the recovery code was sent to you.{br}When you don't see the E-Mail, please check your spamfolder.{br}{br}The token is valid for the next 15 minutes!",
        values: {
            br: <br/>
        }
    },
    'forgot_password_notification_success': {
        id: "forgot_password.notification.success",
        defaultMessage: "Your password was successfully updated{br}{br}You will be automatically redirected to the login in 5 seconds!",
        values: {
            br: <br/>
        }
    },
    'token': {
        id: "token",
        defaultMessage: "Token"
    },
    'optional_field': {
        id: "optional_field",
        defaultMessage: "This field is optional"
    },
    'age': {
        id: "age",
        defaultMessage: "Age"
    },
    'right': {
        id: "right",
        defaultMessage: "Right"
    },
    'wrong': {
        id: "wrong",
        defaultMessage: "Wrong"
    },
    'minutes': {
        id: "minutes",
        defaultMessage: "Minutes"
    },
    'time_minutes_and_seconds': {
        id: "time.minutes_and_seconds",
        defaultMessage: "{minutes} minutes and {seconds} seconds",
    },
    'browser_doesnt_support_video': {
        id: 'browser.doesnt_support_video',
        defaultMessage: "Sorry, your browser doesn't support embedded videos"
    },
    'thumbnail': {
        id: 'thumbnail',
        defaultMessage: "Thumbnail"
    },
    'rest_time_test': {
        id: 'rest_time_test',
        defaultMessage: "Rest time for the whole test"
    },
    'rest_time_task': {
        id: 'rest_time_task',
        defaultMessage: "Rest time for this task"
    },
    'progress_mails': {
        id: 'progress.mails',
        defaultMessage: "{x} out of {y} mails answered"
    },
    'progress_questions': {
        id: 'progress.questions',
        defaultMessage: "{x} out of {y} questions answered"
    },
    'inbox': {
        id: 'inbox',
        defaultMessage: "Inbox"
    },
    'fact_sheets': {
        id: 'fact_sheets',
        defaultMessage: "Fact sheets"
    },
    'ocs_company': {
        id: 'ocs_company',
        defaultMessage: "BROOKLYNMAX CONSULTING{br}YOUR EXPERT IN HEADHUNTING",
        values: {
            br: <br/>
        }
    },
    'rest_minutes_for_this_mail_video': {
        id: 'rest_minutes_for_this_mail_video',
        defaultMessage: "Minutes are left for this mail/video"
    },
    'mail_tasks_info_box_bottom_text': {
        id: 'mail_tasks.info_box_bottom_text',
        defaultMessage: "You have answered {x} from {y} questions.{br}Please answer all {y} questions from this E-Mail in the available time.",
    },
    'from': {
        id: 'from',
        defaultMessage: "From"
    },
    'to': {
        id: 'to',
        defaultMessage: "To"
    },
});

export default messages;