import React, {useEffect} from "react";
import {useStore} from "./store/useStore";
import {IntlProvider} from "react-intl";

// Local files
import translations from "./translations/locales";
import './assets/scss/app.scss';

// DnD
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";

// Components
import Layout from "./pages/layout/Layout";

function App() {
    const {state} = useStore();

    useEffect(() => {
        document.documentElement.className = '';
        document.documentElement.classList.add(`theme-${state.theme}`);
    }, [state.theme]);

    return (
        <IntlProvider
            locale={state.locale}
            defaultLocale="en"
            key={state.locale}
            messages={translations[state.locale]}
        >
            <DndProvider backend={HTML5Backend}>
                <Layout/>
            </DndProvider>
        </IntlProvider>
    );
}

export default App;
