import React from 'react';
import GlobalTrans from "../../helper/GlobalTrans";

const Finished = () => {
    return (
        <div>
            {GlobalTrans('renderer_finished')}
        </div>
    );
};

export default Finished;