import React, {useEffect} from 'react';
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import Content from "../../components/content/Content";
import {Container} from "@mui/material";
import {GlobalTransIntl} from "../../helper/GlobalTrans";
import {useIntl} from "react-intl";
import GetLanguages from "../../helper/GetLanguages";
import {useStore} from "../../store/useStore";
import {isOcsModulePage, isPinFinished} from "../../helper/Helper";

const Layout = () => {
    const intl = useIntl();
    const {state} = useStore();

    useEffect(() => {
        document.title = GlobalTransIntl('document_title', intl);
    }, [intl]);

    const checkForOcs = () => {
        if (isOcsModulePage(state.pinRessource, state.moduleIndex, state.pinStarted, state.pinFinished) && !isPinFinished(state.pinFinished, state.pinRessource)) {
            return <Content/>;
        } else {
            return (
                <Container className='main-content--container container--absolute-center'>
                    <Content/>
                </Container>
            );
        }
    }

    return (
        <React.Fragment>
            <GetLanguages/>
            <Header/>
            <main className='main-content'>
                {
                    checkForOcs()
                }
            </main>
            <Footer/>
        </React.Fragment>
    );
};

export default Layout;