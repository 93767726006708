export const localeInitialState = {
    locale: localStorage.getItem('localStorageLocale') || 'en',
    localeIRI: localStorage.getItem('localStorageLocaleIRI') || '',
};

export const localeActions = {
    setLocale: (state, payload) => {
        if (state.languages.length && payload) {
            const findLanguage = state.languages.find(language => language.code === payload);

            if (findLanguage) {
                localStorage.setItem('localStorageLocale', payload);
                localStorage.setItem('localStorageLocaleIRI', findLanguage['@id']);

                return {
                    locale: payload,
                    localeIRI: findLanguage['@id']
                }
            }
        }

        localStorage.setItem('localStorageLocale', payload);
        localStorage.setItem('localStorageLocaleIRI', '');

        return {
            locale: payload,
            localeIRI: ''
        }
    },
    setLocaleAndIRI: (state, payload) => {
        localStorage.setItem('localStorageLocale', payload['locale']);
        localStorage.setItem('localStorageLocaleIRI', payload['iri']);

        return {
            locale: payload['locale'],
            localeIRI: payload['iri']
        }
    }
};