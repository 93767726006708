export const timerInitialState = {
    moduleTimerTime: 0,
    pageTimerTime: 0,
    triggerFinishPage: undefined,
};

export const timerActions = {
    setModuleTimerTime: (state, payload) => ({ moduleTimerTime: payload }),
    setPageTimerTime: (state, payload) => ({ pageTimerTime: payload }),
    setTriggerFinishPage: (state, payload) => ({ triggerFinishPage: payload }),
};