const localStorageRefreshToken = 'localStorageRefreshToken';

function removeRefreshTokenFromLocalStorage() {
    localStorage.removeItem(localStorageRefreshToken);
}

function getLocalStorageRefreshToken() {
    return localStorage.getItem(localStorageRefreshToken) || '';
}

function deleteRefreshToken() {
    removeRefreshTokenFromLocalStorage();

    return '';
}

export const tokenInitialState = {
    token: '',
    refreshToken: getLocalStorageRefreshToken(),
    tokenExpired: false
};

export const tokenActions = {
    setToken: (state, payload) => ({token: payload}),
    setTokens: (state, payload) => {
        localStorage.setItem(localStorageRefreshToken, payload.refreshToken);

        return {
            token: payload.token,
            refreshToken: payload.refreshToken
        }
    },
    deleteTokens: () => ({token: '', refreshToken: deleteRefreshToken()}),
    setTokenExpired: (state, payload) => ({tokenExpired: payload})
};