import React from 'react';
import PropTypes from "prop-types";
import {useDrag, useDrop} from "react-dnd";

const SortItemElement = (props) => {
    const originalIndex = props.findItem(props.id).index;
    const style = {
        border: '1px dashed gray',
        padding: '0.5rem 1rem',
        cursor: 'move',
    }

    const [{isDragging}, drag] = useDrag(
        () => ({
            type: props.dragType,
            item: {id: props.id, originalIndex},
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
            end: (item, monitor) => {
                const {id: droppedId, originalIndex} = item;
                const didDrop = monitor.didDrop();
                if (!didDrop) {
                    props.moveItem(droppedId, originalIndex);
                }
            },
        }),
        [props.id, originalIndex, props.moveItem],
    )

    const [, drop] = useDrop(
        () => ({
            accept: props.dragType,
            hover({id: draggedId}) {
                if (draggedId !== props.id) {
                    const {index: overIndex} = props.findItem(props.id);
                    props.moveItem(draggedId, overIndex);
                }
            },
        }),
        [props.findItem, props.moveItem],
    )

    const opacity = isDragging ? 0 : 1;

    return (
        <div ref={(node) => drag(drop(node))} style={{...style, opacity}}>
            {props.text}
        </div>
    )
};

SortItemElement.propTypes = {
    id: PropTypes.any.isRequired,
    text: PropTypes.string.isRequired,
    moveItem: PropTypes.func.isRequired,
    findItem: PropTypes.func.isRequired,
    dragType: PropTypes.string.isRequired,
};

export default SortItemElement;