const apiHost = process.env.REACT_APP_API_HOST;

const config = {
    apiHost,
    apiUrl: `${apiHost}/api`,
    axiosConfig: function (token, config = {}) {
        return {
            ...config,
            withCredentials: true,
            headers: {
                ...config.headers,
                Authorization: `Bearer ${token}`
            }
        }
    },
    defaultLocale: 'en',
}

export default config;