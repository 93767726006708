import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import {StoreProvider} from "./store/useStore";
import * as Sentry from "@sentry/react";

if (process.env.REACT_APP_SENTRY === 'prod') {
    Sentry.init({
        dsn: "https://694433856d12f4a42b50f6f6141528f1@o4508012722847744.ingest.de.sentry.io/4508019393429584",
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],
        // Tracing
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost"],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
} else if (process.env.REACT_APP_SENTRY === 'stage') {
    Sentry.init({
        dsn: "https://8167f1ec0271e6a4bccacc91e82a2537@o4508012722847744.ingest.de.sentry.io/4508053828862032",
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],
        // Tracing
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost"],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

ReactDOM.render(
    <StoreProvider>
        <App/>
    </StoreProvider>,
    document.getElementById('root')
);
