import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {Box} from "@mui/material";

const Timer = (props) => {
    const [time, setTime] = useState(0);
    const [isActive, setIsActive] = useState(false);
    const [timerInterval, setTimerInterval] = useState(0);

    const minutes = String(Math.floor(time / 60)).padStart(2, '0');
    const seconds = String(time % 60).padStart(2, '0');

    useEffect(() => {
        const newTime = props.initialSeconds || 0;
        setTime(newTime);

        if (newTime > 0) {
            setIsActive(true);
        } else {
            setIsActive(false);
        }
    }, [props.initialSeconds]);

    useEffect(() => {
        if (!isActive && timerInterval) {
            clearInterval(timerInterval);
            setTimerInterval(0);
        }
    }, [isActive, timerInterval]);

    useEffect(() => {
        if (isActive && !timerInterval) {
            setTimerInterval(setInterval(() => {
                setTime((prev) => prev - 1)
            }, 1000));
        }

        return () => {
            if (timerInterval) {
                clearInterval(timerInterval);
            }
        }
    }, [isActive, timerInterval]);

    useEffect(() => {
        if (isActive && time === 0) {
            setIsActive(false);

            if (props.onTimeResponseFinish) {
                props.onTimeResponseFinish(props.finishParameter);
            }
        }
    }, [isActive, time, props]);

    return (
        time !== 0 &&
        <Box sx={props.styling}>
            {`${minutes}:${seconds}`}
        </Box>
    );
};

Timer.propTypes = {
    onTimeResponseFinish: PropTypes.func,
    initialSeconds: PropTypes.number,
    styling: PropTypes.object,
    finishParameter: PropTypes.any,
}

export default Timer;