import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {FormControl, Grid, Typography} from "@mui/material";
import Radio from '@mui/material/Radio';
import GetTranslation from "../../../helper/GetTranslation";
import config from "../../../config/config";
import {useStore} from "../../../store/useStore";
import GlobalTrans from "../../../helper/GlobalTrans";

const RendererRightAndWrong = (props) => {
    const {state} = useStore();

    const [answered, setAnswered] = useState(false);
    const [selectedRightValue, setSelectedRightValue] = useState('');
    const [selectedWrongValue, setSelectedWrongValue] = useState('');
    const [triggerChangeHandler, setTriggerChangeHandler] = useState(false);

    useEffect(() => {
        if (triggerChangeHandler && selectedRightValue !== '' && selectedWrongValue !== '') {
            setTriggerChangeHandler(false);

            props.onChangeHandler({
                right: selectedRightValue,
                wrong: selectedWrongValue
            });

            if (!answered) {
                setAnswered(true);
                props.answered();
            }
        }
    }, [selectedRightValue, selectedWrongValue, answered, props, triggerChangeHandler]);

    const handleChange = (event, target) => {
        const value = parseInt(event.target.value, 10);

        if (target === 'right') {
            if (selectedWrongValue === value) {
                setSelectedWrongValue('');

                if (answered) {
                    props.decreaseAnswered();
                    setAnswered(false);
                }
            }

            setTriggerChangeHandler(true);
            setSelectedRightValue(value);
        } else if(target === 'wrong') {
            if (selectedRightValue === value) {
                setSelectedRightValue('');

                if (answered) {
                    props.decreaseAnswered();
                    setAnswered(false);
                }
            }

            setTriggerChangeHandler(true);
            setSelectedWrongValue(value);
        }
    };

    const getTranslation = useCallback(() => {
        if (props.element?.translations) {
            const translation = props.element.translations.find(translation => translation.language === state.localeIRI);

            if (translation) {
                return translation;
            }
        }

        return null;
    }, [props.element.translations, state.localeIRI]);

    const getOptionTranslation = useCallback((index) => {
        if (!props.element.options[index]) {
            return index;
        }

        if (state.localeIRI !== config.defaultLocale) {
            const translation = getTranslation();

            if (translation && translation.options) {
                return translation.options[index];
            }

            return props.element.options[index];
        } else {
            return props.element.options[index];
        }
    }, [props.element.options, state.localeIRI, getTranslation]);

    return (
        <React.Fragment>
            <Typography mb={2}>
                <GetTranslation
                    translations={props.element?.translations}
                    defaultContent={props.element.question}
                    translationField={'question'}
                />
            </Typography>
            <FormControl component="fieldset">
                <Grid container spacing={2} alignItems={"center"} sx={{mb: 2}}>
                    <Grid item xs={8}></Grid>
                    <Grid item xs={6} sm={2} textAlign={"center"}>
                        {GlobalTrans('right')}
                    </Grid>
                    <Grid item xs={6} sm={2} textAlign={"center"}>
                        {GlobalTrans('wrong')}
                    </Grid>

                {props.element.options.map((option, index) => (
                    <React.Fragment key={index}>
                        <Grid item xs={12} sm={8}>
                            {getOptionTranslation(index)}
                        </Grid>
                        <Grid item xs={6} sm={2} textAlign={"center"}>
                            <Radio
                                checked={selectedRightValue === index}
                                onChange={(e) => handleChange(e, 'right')}
                                value={index}
                                name="radio-buttons-right"
                            />
                        </Grid>
                        <Grid item xs={6} sm={2} textAlign={"center"}>
                            <Radio
                                checked={selectedWrongValue === index}
                                onChange={(e) => handleChange(e, 'wrong')}
                                value={index}
                                name="radio-buttons-wrong"
                            />
                        </Grid>
                    </React.Fragment>
                ))}
                </Grid>
            </FormControl>
        </React.Fragment>
    );
}

RendererRightAndWrong.propTypes = {
    element: PropTypes.object.isRequired,
    onChangeHandler: PropTypes.func.isRequired,
    answered: PropTypes.func.isRequired,
    decreaseAnswered: PropTypes.func.isRequired,
}

export default RendererRightAndWrong;