import {useStore} from "../store/useStore";
import Token from "./Token";
import {useCallback, useEffect, useState} from "react";
import axios from "axios";

const TokenRefresher = () => {
    const { state, dispatch } = useStore();

    const [cancelToken] = useState(axios.CancelToken.source());

    const loop = useCallback((refreshToken) => {
        dispatch({
            type: 'setRefresherLoop',
            payload: setTimeout(() => {
                Token.refresh(
                    (res) => {
                        dispatch({
                            type: 'setTokens',
                            payload: {
                                token: res.data.token,
                                refreshToken: res.data.refresh_token
                            }
                        })

                        loop(res.data.refresh_token);
                    },
                    () => {
                        dispatch({type: "deleteTokens"});
                        dispatch({type: "setTokenExpired", payload: true});
                        dispatch({type: 'setRefresher', payload: false});
                    },
                    refreshToken,
                    cancelToken.token
                )
            }, 14 * 60 * 1000)
        });
    }, [dispatch, cancelToken]);

    useEffect(() => {
        if (state.refresher === false && state.refreshToken) {
            loop(state.refreshToken);

            dispatch({
                type: 'setRefresher',
                payload: true
            });
        }
    }, [state.refresher, dispatch, loop, state.refreshToken]);

    return null;
}

export default TokenRefresher;