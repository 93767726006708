import {useCallback, useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {useStore} from "../store/useStore";

const GetTranslation = (props) => {
    const {state} = useStore();

    const [mounted, setMounted] = useState(false);
    const [content, setContent] = useState(props.defaultContent || '');
    const [contentLocale, setContentLocale] = useState(state.localeIRI || '');

    const getTranslation = useCallback(() => {
        if (props?.translations?.length) {
            const translation = (typeof props.translations[0].language === 'object') ?
                props.translations.find(translation => translation.language['@id'] === state.localeIRI) :
                props.translations.find(translation => translation.language === state.localeIRI);

            if (translation) {
                setContent(translation[props.translationField || 'content']);
                setContentLocale(state.localeIRI);
            }
        }
    }, [props.translations, state.localeIRI, props.translationField]);

    useEffect(() => {
        if (!mounted) {
            setMounted(true);
            getTranslation();
        }
    }, [mounted, getTranslation]);

    useEffect(() => {
        if (state.localeIRI !== contentLocale) {
            getTranslation();
        }
    }, [state.localeIRI, contentLocale, getTranslation]);

    return content;
};

GetTranslation.propTypes = {
    translations: PropTypes.array,
    defaultContent: PropTypes.string,
    translationField: PropTypes.string,
}

export default GetTranslation;