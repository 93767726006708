export const pinInitialState = {
    pin: localStorage.getItem('localStoragePin') || '',
    pinId: Number(localStorage.getItem('localStoragePinId')) || '',
    pinHasOwner: localStorage.getItem('localStoragePinHasOwner') === 'true' || false,
    pinRessource: {},
    pinStarted: localStorage.getItem('localStoragePinStarted') === 'true' || false,
    pinFinished: false,
};

export const pinActions = {
    setPin: (state, payload) => {
        localStorage.setItem('localStoragePin', payload);

        return {
            pin: payload
        }
    },
    setPinId: (state, payload) => {
        localStorage.setItem('localStoragePinId', payload);

        return {
            pinId: payload
        }
    },
    setPinHasOwner: (state, payload) => {
        localStorage.setItem('localStoragePinHasOwner', payload);

        return {
            pinHasOwner: payload
        }
    },
    setPinRessource: (state, payload) => ({pinRessource: payload}),
    deletePin: () => {
        localStorage.removeItem('localStoragePin');
        localStorage.removeItem('localStoragePinId');
        localStorage.removeItem('localStoragePinHasOwner');
        localStorage.removeItem('localStoragePinStarted');

        return {
            pin: '',
            pinId: '',
            pinHasOwner: false,
            pinRessource: {},
            pinStarted: false,
            pinFinished: false
        }
    },
    startPin: () => {
        localStorage.setItem('localStoragePinStarted', 'true');

        return {pinStarted: true}
    },
    finishPin: () => {
        return {pinFinished: true}
    }
};