import React from 'react';
import PropTypes from "prop-types";
import GetTranslation from "../../../helper/GetTranslation";
import GlobalTrans from "../../../helper/GlobalTrans";
import config from "../../../config/config";
import {Box} from "@mui/material";

const RendererVideo = (props) => {
    const getVideoSource = () => {
        const src = GetTranslation({
            translations: props.element?.translations,
            defaultContent: props.element.src,
            translationField: 'src'
        });

        if (src?.contentUrl) {
            return config.apiHost + src.contentUrl;
        }

        return null;
    }

    return (
        <Box
            component={"video"}
            src={getVideoSource()}
            width={props.element.width ? props.element.width + 'px' : null}
            maxWidth={'100%'}
            poster={props.element.thumb}
            controls={!props.element.deactivateControls}
        >
            {GlobalTrans('browser_doesnt_support_video')}
        </Box>
    );
};

RendererVideo.propTypes = {
    element: PropTypes.object.isRequired
}

export default RendererVideo;