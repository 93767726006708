import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {Grid} from "@mui/material";
import RendererRadio from "./RendererRadio";
import RendererImage from "./RendererImage";
import {useStore} from "../../../store/useStore";
import Timer from "../Timer";
import {getLastPageAnsweredAt, getRestTimeByTimestamps} from "../../../helper/Helper";

const RendererAdaptive = (props) => {
    const {state} = useStore();
    const [mounted, setMounted] = useState(false);
    const [activeItem, setActiveItem] = useState(false);
    const [activeItemIndex, setActiveItemIndex] = useState(false);
    const [timerTime, setTimerTime] = useState(0);
    const [initTimerTime, setInitTimerTime] = useState(false);

    useEffect(() => {
        if (!mounted) {
            setMounted(true);

            let nextItem, nextItemIndex;

            if (props.answers?.next) {
                nextItem = props.element.items.find(item => item.id === props.answers.next);
                nextItemIndex = props.element.items.findIndex(item => item.id === nextItem.id);
            } else if (!Object.keys(props.answers).length) {
                const keys = Object.keys(props.element.items);
                nextItem = props.element.items[keys[0]];
                nextItemIndex = keys[0];
            } else {
                props.setReadyForNextPage(true);
                props.triggerFinishedPage();

                return;
            }

            setActiveItem(nextItem);
            setActiveItemIndex(nextItemIndex);

            props.setReadyForNextPage(false);
        }
    }, [mounted, props]);

    const getImage = useCallback(() => {
        if (state.localeIRI && props.element?.translations?.[activeItemIndex]) {
            const translation = props.element.translations[activeItemIndex].find(translation => translation.language === state.localeIRI);

            if (translation?.image) {
                return <RendererImage element={{src: translation.image.contentUrl}}/>;
            }
        }

        if (activeItem.image?.contentUrl) {
            return <RendererImage element={{src: activeItem.image.contentUrl}}/>;
        }

        return null;
    }, [activeItem, activeItemIndex, props.element.translations, state.localeIRI]);

    const renderItem = useCallback(() => {
        if (!activeItem) {
            return null;
        }

        return (
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    {getImage()}
                </Grid>
                <Grid item xs={12} sm={6}>
                    <RendererRadio
                        element={{
                            ...activeItem,
                            translations: props.element?.translations[activeItemIndex]
                        }}
                        onChangeHandler={props.onChangeHandler}
                        answered={props.answered}
                    />
                </Grid>
            </Grid>
        );
    }, [activeItem, props.answered, props.onChangeHandler, props.element?.translations, getImage, activeItemIndex]);

    const timerFinish = useCallback(() => {
        props.onChangeHandler(false);
        props.setTriggerFinishPageValue(false);
        props.triggerFinishedPage();
    }, [props]);

    const getTimerSeconds = useCallback(() => {
        if (props.element?.globalTime) {
            return props.element.globalTime;
        }

        if (activeItem?.time) {
            return activeItem.time;
        }

        return 0;
    }, [activeItem.time, props.element.globalTime]);

    const getLastAnswerTimestamp = useCallback(() => {
        if (props.answers?.answers?.length) {
            const count = props.answers.answers.length;
            const lastAnswer = props.answers.answers[count - 1];

            return lastAnswer.answeredAt;
        }

        return false;
    }, [props.answers.answers]);

    useEffect(() => {
        if (initTimerTime) {
            return;
        }

        setInitTimerTime(true);

        const seconds = getTimerSeconds();

        if (!seconds) {
            setTimerTime(0);
        } else {
            let lastAnsweredTime = getLastAnswerTimestamp();

            if (!lastAnsweredTime) {
                const moduleIndex = (state.moduleIndex >= 0) ? state.moduleIndex : state.pinRessource.currentModule;
                const pageIndex = (state.pageIndex >= 0) ? state.pageIndex : state.pinRessource.currentPage;
                const answerJson = (Object.keys(state.answerJson).length) ? state.answerJson : (state.pinRessource.answerJson ?? []);

                lastAnsweredTime = getLastPageAnsweredAt(state.pinRessource.modulesJson, pageIndex, moduleIndex, answerJson, state.pinRessource.startedAt);
            }

            const restTime = getRestTimeByTimestamps(lastAnsweredTime, seconds);

            if (restTime) {
                setTimerTime(restTime);
            } else {
                timerFinish();
            }
        }
    }, [initTimerTime, getTimerSeconds, state.answerJson, state.moduleIndex, state.pageIndex, state.pinRessource.answerJson, state.pinRessource.currentModule, state.pinRessource.currentPage, state.pinRessource.modulesJson, state.pinRessource.startedAt, getLastAnswerTimestamp, timerFinish]);

    return (
        <div className={'adaptive'}>
            {
                (activeItem) &&
                <Timer
                    initialSeconds={timerTime}
                    onTimeResponseFinish={timerFinish}
                    styling={{
                        fontSize: 32,
                        fontWeight: 'bold'
                    }}
                />
            }
            {renderItem()}
        </div>
    );
};

RendererAdaptive.propTypes = {
    element: PropTypes.object.isRequired,
    onChangeHandler: PropTypes.func.isRequired,
    answered: PropTypes.func.isRequired,
    answers: PropTypes.object.isRequired,
    setReadyForNextPage: PropTypes.func.isRequired,
    triggerFinishedPage: PropTypes.func.isRequired,
    setTriggerFinishPageValue: PropTypes.func,
}

export default RendererAdaptive;