import de from './de.json';
import es from './es.json';
import it from './it.json';
import pl from './pl.json';

// English is the default message in each of our source files, so no need for a separate en.json file
const en = {};

const locales = { en, de, es, it, pl };

// If we add more than just spanish, just import the files and export it below: export default { en, es, de }
export default locales;