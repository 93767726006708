import React from 'react';
import PropTypes from "prop-types";
import {Box, Typography} from "@mui/material";
import GetTranslation from "../../../helper/GetTranslation";

const RendererTextarea = (props) => {
    return (
        <Box whiteSpace={'pre-line'}>
            <Typography>
                <GetTranslation
                    translations={props.element?.translations}
                    defaultContent={props.element.content}
                    translationField={'content'}
                />
            </Typography>
        </Box>
    );
};

RendererTextarea.propTypes = {
    element: PropTypes.object.isRequired
}

export default RendererTextarea;