import {useEffect, useState} from 'react';
import axios from "axios";
import {useStore} from "../store/useStore";
import config from "../config/config";

const GetLanguages = () => {
    const {state, dispatch} = useStore();
    const [cancelToken] = useState(axios.CancelToken.source());

    useEffect(() => {
        return () => {
            cancelToken.cancel();
        };
    }, [cancelToken]);

    useEffect(() => {
        if (!state.loadedLanguages) {
            axios.get(config.apiUrl + `/languages`, {cancelToken: cancelToken.token})
                .then(res => {
                    if (res.data) {
                        dispatch({
                            type: "setLoadedLanguages"
                        });
                        dispatch({
                            type: "setLanguages",
                            payload: res.data['hydra:member']
                        });
                    }
                })
                .catch((error) => {console.log(error);});
        }
    }, [state.token, state.languages, state.loadedLanguages, cancelToken, dispatch]);

    return null;
};

export default GetLanguages;