import axios from "axios";
import config from "../config/config";

const PinValidator = (pin, locale, cancelToken, callbackSuccess, callbackError, callbackCatch) => {
    axios.get(config.apiUrl + `/pin/${locale}/validate?pin=${pin}`, {cancelToken: cancelToken.token})
        .then(res => {
            if (res.data && res.data['success']) {
                callbackSuccess(res.data);
            } else {
                callbackError(res.data);
            }
        })
        .catch((error) => {
            callbackCatch(error);
        });
};

export default PinValidator;