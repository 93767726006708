import React from 'react';
import PropTypes from "prop-types";
import {Typography} from "@mui/material";
import GetTranslation from "../../../helper/GetTranslation";

const RendererText = (props) => {
    return (
        <Typography>
            <GetTranslation
                translations={props.element?.translations}
                defaultContent={props.element.content}
                translationField={'content'}
            />
        </Typography>
    );
};

RendererText.propTypes = {
    element: PropTypes.object.isRequired
}

export default RendererText;