export const refresherInitialState = {
    refresher: false,
    refresherLoop: 0
};

export const refresherActions = {
    setRefresher: (state, payload) => ({ refresher: payload }),
    setRefresherLoop: (state, payload) => ({refresherLoop: payload}),
    resetRefresher: (state) => {
        clearTimeout(state.refresherLoop);

        return refresherInitialState;
    }
};