import React from 'react';
import PropTypes from "prop-types";
import DOMPurify from 'dompurify';
import GetTranslation from "../../../helper/GetTranslation";

const RendererHtml = (props) => {
    const translation = GetTranslation({
        translations: props.element?.translations,
        defaultContent: props.element.content
    });

    return <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(translation)}}/>;
};

RendererHtml.propTypes = {
    element: PropTypes.object.isRequired
}

export default RendererHtml;