export const rendererInitialState = {
    moduleIndex: undefined,
    pageIndex: undefined,
    answerJson: {}
};

export const rendererActions = {
    setModuleIndex: (state, payload) => ({
        moduleIndex: payload
    }),
    setPageIndex: (state, payload) => ({
        pageIndex: payload
    }),
    setAnswerJson: (state, payload) => ({
        answerJson: payload
    })
};