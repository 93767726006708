import React, {useCallback} from "react";

import {FormControl, Grid, IconButton, InputLabel, MenuItem, Select, useMediaQuery} from "@mui/material";
import {DarkMode, LightMode} from "@mui/icons-material";

import {useStore} from "../../store/useStore";
import GlobalTrans, {GlobalTransIntl} from "../../helper/GlobalTrans";
import {useTheme} from "@mui/material/styles";
import config from "../../config/config";
import {useIntl} from "react-intl";

function Navigation() {
    const intl = useIntl();

    const {state, dispatch} = useStore();
    const themeSwitchIconClass = 'theme-switch--' + ((state.theme === 'light') ? 'dark' : 'light');
    const theme = useTheme();
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

    const setLocale = useCallback(
        (locale) => {
            dispatch({type: "setLocale", payload: locale});
        }, [dispatch]);

    const changeTheme = () => {
        if (state.theme === 'light') {
            localStorage.setItem('localStorageTheme', 'dark');
            dispatch({type: "setTheme", payload: 'dark'})
        } else {
            localStorage.setItem('localStorageTheme', 'light');
            dispatch({type: "setTheme", payload: 'light'})
        }
    };

    const canShowLanguageSelect = () => {
        return !!state.languages.length && (!state.pinRessource || !state.pinRessource.isForcedLanguage);
    }

    const getLanguageTranslationName = useCallback((language) => {
        if (language?.translations) {
            const translation = language.translations.find(translation => translation.language === state.localeIRI);

            if (translation?.name) {
                return translation.name;
            }
        }

        return language.name;
    }, [state.localeIRI]);

    return (
        <Grid container alignItems={'center'} justifyContent={'flex-end'} spacing={4}>
            <Grid item xs={6} sm={"auto"} textAlign={(isSmDown) ? 'center' : null}>
                <IconButton aria-label={() => GlobalTrans('theme.switch')} onClick={changeTheme}
                            className={themeSwitchIconClass}>
                    {
                        (state.theme === 'light' && <DarkMode/>)
                        ||
                        <LightMode/>
                    }
                </IconButton>
            </Grid>
            {
                canShowLanguageSelect() &&
                <Grid item xs={6} sm={"auto"} textAlign={(isSmDown) ? 'center' : null}>
                    <FormControl variant="standard" sx={{m: 1, minWidth: 120}}>
                        <InputLabel id="locale-label">
                            {GlobalTransIntl('languages_select', intl)}
                        </InputLabel>
                        <Select
                            id="locale"
                            labelId='locale-label'
                            value={state.locale}
                            label={GlobalTransIntl('languages_select', intl)}
                        >
                            <MenuItem onClick={() => setLocale(config.defaultLocale)} value={config.defaultLocale}>
                                {GlobalTransIntl('english', intl)}
                            </MenuItem>
                            {
                                state.languages.map(language => {
                                    if (language.isPublic) {
                                        return (
                                            <MenuItem
                                                onClick={() => setLocale(language.code)}
                                                value={language.code}
                                                key={language.code}
                                            >
                                                {getLanguageTranslationName(language)}
                                            </MenuItem>
                                        )
                                    } else {
                                        return null;
                                    }
                                })
                            }
                        </Select>
                    </FormControl>
                </Grid>
            }
        </Grid>
    )
}

export default Navigation;