import React from 'react';
import PropTypes from "prop-types";
import config from "../../../config/config";

const RendererImage = (props) => {
    return (
        <img src={config.apiHost + props.element.src} alt={''} className={'image-responsive'}/>
    );
};

RendererImage.propTypes = {
    element: PropTypes.object.isRequired
}

export default RendererImage;