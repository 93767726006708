import React, {createContext, useReducer, useContext} from "react";
import PropTypes from "prop-types";
import {themeActions, themeInitialState} from "../actions/theme";
import {localeActions, localeInitialState} from "../actions/locale";
import {pinActions, pinInitialState} from "../actions/pin";
import {tokenActions, tokenInitialState} from "../actions/token";
import {refresherActions, refresherInitialState} from "../actions/refresher";
import {rendererActions, rendererInitialState} from "../actions/renderer";
import {languagesActions, languagesInitialState} from "../actions/languages";
import {finishingPageActions, finishingPageInitialState} from "../actions/finishingPage";
import {timerActions, timerInitialState} from "../actions/timer";

export const initialState = {
    ...themeInitialState,
    ...localeInitialState,
    ...pinInitialState,
    ...tokenInitialState,
    ...refresherInitialState,
    ...rendererInitialState,
    ...languagesInitialState,
    ...finishingPageInitialState,
    ...timerInitialState
};

const StoreContext = createContext(initialState);

const Actions = {
    ...themeActions,
    ...localeActions,
    ...pinActions,
    ...tokenActions,
    ...refresherActions,
    ...rendererActions,
    ...languagesActions,
    ...finishingPageActions,
    ...timerActions
};

const reducer = (state, action) => {
    const {type, payload} = action;
    const act = Actions[type];
    const update = act(state, payload);
    return {...state, ...update};
};

export const StoreProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <StoreContext.Provider value={{state, dispatch}}>
            {children}
        </StoreContext.Provider>
    );
};

StoreProvider.propTypes = {
    children: PropTypes.node
};

export const useStore = () => {
    const {state, dispatch} = useContext(StoreContext);
    return {state, dispatch};
};
