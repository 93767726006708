import jwtDecode from "jwt-decode";
import axios from "axios";
import config from "../config/config";

const Token = {
    validate: (token) => {
        let decodedToken = jwtDecode(token),
            timestampExpire = decodedToken.exp,
            timestampNow = Math.floor(Date.now() / 1000);

        return timestampExpire > timestampNow;
    },
    refresh: (callbackSuccess, callbackError, refreshToken, cancelToken) => {
        let data = {
            refresh_token: refreshToken
        };

        axios.post(config.apiUrl + `/token/refresh`, data, {cancelToken, withCredentials: true})
            .then(res => {
                if (res.data.token && res.data.refresh_token) {
                    callbackSuccess(res);
                } else {
                    callbackError();
                }
            })
            .catch(() => {
                callbackError();
            });
    }
};

export default Token;