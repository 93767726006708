import {GlobalTransIntl} from "../../helper/GlobalTrans";
import React from "react";
import PropTypes from "prop-types";
import {useIntl} from "react-intl";
import {Alert, Grid} from "@mui/material";

function Notifications(props) {
    const intl = useIntl();

    if (!props.success && !props.error && !props.info && !props.warning) {
        return null;
    }

    return (
        <React.Fragment>
            <Grid container {...props.grid}>
                <Grid item {...props.cols}>
                    {
                        props.success &&
                        <Alert severity='success'>
                            {
                                props.successMessage || GlobalTransIntl('notification_success', intl)
                            }
                        </Alert>
                    }
                    {
                        props.error &&
                        <Alert severity='error'>
                            {
                                props.errorMessage || GlobalTransIntl('notification_error', intl)
                            }
                        </Alert>
                    }
                    {
                        props.info &&
                        <Alert severity='info'>
                            {
                                props.infoMessage || ''
                            }
                        </Alert>
                    }
                    {
                        props.warning &&
                        <Alert severity='warning'>
                            {
                                props.warningMessage || ''
                            }
                        </Alert>
                    }
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

Notifications.propTypes = {
    success: PropTypes.bool,
    error: PropTypes.bool,
    info: PropTypes.bool,
    warning: PropTypes.bool,
    successMessage: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),
    errorMessage: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.object,
    ]),
    infoMessage: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),
    warningMessage: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),
    cols: PropTypes.object,
    grid: PropTypes.object
};

Notifications.defaultProps = {
    success: false,
    error: false,
    info: false,
    warning: false,
    cols: {
        xs: 12,
        lg: 6
    },
    grid: null
}

export default Notifications;