import React, {useState} from 'react';
import {useStore} from "../../store/useStore";
import {Box, Button, Checkbox, FormControlLabel, Grid, Paper} from "@mui/material";
import GlobalTrans from "../../helper/GlobalTrans";
import axios from "axios";
import Notifications from "../../components/notifications/Notifications";
import config from "../../config/config";
import GetTranslation from "../../helper/GetTranslation";
import DOMPurify from "dompurify";

const Frontpage = () => {
    const {state, dispatch} = useStore();

    const [cancelToken] = useState(axios.CancelToken.source());
    const [dataProtectionCheckbox, setDataProtectionCheckbox] = useState(false);

    // Notification
    const [notificationError, setNotificationError] = useState(false);

    const onSubmit = (event) => {
        event.preventDefault();

        let data = {
            changeStatus: 3
        }

        axios.patch(config.apiUrl + `/pins/` + state.pinId, data, config.axiosConfig(state.token, {cancelToken: cancelToken.token, headers: {'content-type': 'application/merge-patch+json'}}))
            .then((res) => {
                if (res.status === 200) {
                    dispatch({
                        type: 'startPin'
                    });

                    dispatch({
                        type: 'setPinRessource',
                        payload: res.data
                    });
                } else {
                    setNotificationError(true)
                }
            })
            .catch(() => setNotificationError(true));
    }

    return (
        <Paper className={'color-theme main-paper'}>
            <h1>
                <GetTranslation
                    translations={state.pinRessource.procedureObject.translations}
                    defaultContent={state.pinRessource.procedureObject.frontpageHeadline}
                    translationField={'frontpageHeadline'}
                />
            </h1>

            <div dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                    GetTranslation({
                        translations: state.pinRessource.procedureObject?.translations,
                        defaultContent: state.pinRessource.procedureObject?.frontpageText,
                        translationField: 'frontpageText'
                    })
                )
            }}/>

            {
                (
                    !state.pinRessource.isDeactivateDataProtection &&
                    (
                        <React.Fragment>
                            <h2>{GlobalTrans('data_protection_headline')}</h2>
                            {
                                <div dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(
                                        state.pinRessource.dataProtection?.value?.length > 0 ?
                                            GetTranslation({
                                                translations: state.pinRessource.dataProtection?.translations,
                                                defaultContent: state.pinRessource.dataProtection?.value,
                                                translationField: 'value'
                                            }) :
                                            GlobalTrans('data_protection_text')
                                    )
                                }}/>
                            }
                        </React.Fragment>
                    )
                ) || (
                    <Box mb={2}/>
                )
            }

            <Notifications
                error={notificationError}
            />
            <form onSubmit={onSubmit}>
                <Grid container className={'login'} rowSpacing={2}>
                    {
                        !state.pinRessource.isDeactivateDataProtection &&
                        (
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={dataProtectionCheckbox}
                                            onChange={
                                                (e) => {
                                                    setDataProtectionCheckbox(e.target.checked)
                                                }
                                            }
                                            color='primary'
                                            required
                                        />
                                    }
                                    label={
                                        <React.Fragment>
                                            {GlobalTrans('data_protection_accept')}
                                        </React.Fragment>
                                    }
                                />
                            </Grid>
                        )
                    }

                    <Grid item xs={12}>
                        <Button variant={"contained"} type={"submit"}>
                            {GlobalTrans('continue')}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Paper>
    );
};

export default Frontpage;