import React, {useCallback} from 'react';
import PropTypes from "prop-types";
import {Grid} from "@mui/material";
import GetElement from "../../../helper/GetElement";

const RendererGrid = (props) => {
    const Cols = useCallback(() => {
        let cols = [];

        for (let i = 0; i < props.element.colAmount; i++) {
            const colElements = props.element.colElements[i];

            cols.push(
                <Grid item xs={12} sm key={i}>
                    {
                        colElements.map((element) => (
                            <GetElement
                                key={element.id}
                                element={element}
                                setValues={props.setValues}
                                increaseAnswered={props.increaseAnswered}
                                decreaseAnswered={props.decreaseAnswered}
                                answerJson={props.answerJson}
                                setReadyForNextPage={props.setReadyForNextPage}
                                pageId={props.pageId}
                            />
                        ))
                    }
                </Grid>
            );
        }

        return cols;
    }, [props.increaseAnswered, props.decreaseAnswered, props.element.colAmount, props.element.colElements, props.setValues, props.setReadyForNextPage, props.answerJson, props.pageId]);

    return (
        <Grid container spacing={2}>
            <Cols/>
        </Grid>
    );
};

RendererGrid.propTypes = {
    element: PropTypes.object.isRequired,
    setValues: PropTypes.func.isRequired,
    increaseAnswered: PropTypes.func.isRequired,
    decreaseAnswered: PropTypes.func.isRequired,
    answerJson: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    setReadyForNextPage: PropTypes.func.isRequired,
    pageId: PropTypes.string.isRequired,
}

export default RendererGrid;