import React from 'react';
import {Box, CircularProgress} from "@mui/material";

const RefreshToken = () => {
    return (
        <Box sx={{textAlign: 'center', p: 4}}>
            <CircularProgress/>
        </Box>
    );
};

export default RefreshToken;